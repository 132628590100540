export enum HelenFieldType {
  String = 'string',
  Integer = 'integer',
}

export interface HelenStructure<T extends string> {
  schema?: {
    fields: {
      name: T;
      type: HelenFieldType;
    }[],
  },
  data?: {
    [K in T]: string | number;
  }[]
}
