import React from 'react';
import { Row, Col } from 'antd';
import { CheckTable } from './CheckTable';
import { HelenStructure } from './models';
import { StructureInput } from './StructureInput';
import styles from './Helen.module.css'

export const Helen = () => {
  const [structure, setStructure] = React.useState<HelenStructure<string> | null>(null);
  return (
    <Row>
      <Col span={16} className={styles.leftColumn}>
        <CheckTable structure={structure} setStructure={setStructure} />
      </Col>
      <Col span={8}>
        <StructureInput structure={structure} setStructure={setStructure} />
      </Col>
    </Row>
  );
};
