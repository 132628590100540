import React from 'react';
import { Work } from '../Work';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routes } from '../../const/routes';
import { LeetCode } from '../LeetCode';
import { App } from '../App';
import { Helen } from '../Helen';

const router = createBrowserRouter([
  {
    path: routes.home,
    element: <App />,
    children: [
      {
        path: routes.home,
        element: <Work />,
      },
      {
        path: routes.work,
        element: <Work />,
      },
      {
        path: routes.leetcode,
        element: <LeetCode />,
      },
      {
        path: routes.helen,
        element: <Helen />,
      },
    ],
  },
]);

export const Router = () => {
  return <RouterProvider router={router} />;
};
