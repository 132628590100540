import React, { useMemo } from 'react';
import { Table, Checkbox } from 'antd';
import { HelenFieldType, HelenStructure } from '../models';

export interface CheckTableProps {
  structure: HelenStructure<string> | null;
  setStructure: React.Dispatch<React.SetStateAction<HelenStructure<string> | null>>;
}

export const CheckTable = ({structure, setStructure}: CheckTableProps) => {
  const columns = useMemo(() => {
    if (!structure?.schema?.fields?.length) {
      return [];
    }

    try {
      return structure.schema.fields.map(({ name, type }) => ({
        title: name,
        key: name,
        dataIndex: name,
        render: (value: string | number, _: unknown, index: number) => {
          if (type !== HelenFieldType.Integer) {
            return value;
          }

          return <Checkbox checked={!!value} onChange={() => setStructure(prevStructure => {
            const newData = prevStructure?.data?.slice();
            if (!newData) {
              return null;
            }

            newData[index] = {
              ...newData[index],
              [name]: !value ? 1 : 0
            };

            return ({
              ...prevStructure,
              data: newData,
            });
          })} />
        }
      }))
    } catch {}
  }, [structure?.schema?.fields, setStructure]);

  const data = useMemo(() => {
    if (!structure?.data?.length) {
      return [];
    }

    return structure.data;
  }, [structure?.data]);

  return (
    <Table columns={columns} dataSource={data} pagination={false} />
  );
};
