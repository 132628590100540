import React from 'react';
import styles from './App.module.css';
import { Layout, Menu, ConfigProvider, theme } from 'antd';
import { Link, Outlet } from 'react-router-dom';
import { routes } from '../../const/routes';

export const App = () => {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <Layout>
        <Layout.Header>
          <Menu
            theme="dark"
            mode="horizontal"
            items={[
              { key: 'Work', label: <Link to={routes.work}>Work</Link> },
              {
                key: 'LeetCode',
                label: <Link to={routes.leetcode}>LeetCode</Link>,
              },
              {
                key: 'Helen',
                label: <Link to={routes.helen}>Helen</Link>,
              },
            ]}
          />
        </Layout.Header>
        <Layout.Content className={styles.content}>
          <Outlet />
        </Layout.Content>
      </Layout>
    </ConfigProvider>
  );
};
