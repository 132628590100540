import React, { useDeferredValue, useMemo, useRef } from 'react';
import { Button, Input, Space, Upload } from 'antd';
import { HelenStructure } from '../models';
import styles from './StructureInput.module.css'
import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import { downloadJSON } from './helpers';

export interface StructureInputProps {
  structure: HelenStructure<string> | null;
  setStructure: React.Dispatch<React.SetStateAction<HelenStructure<string> | null>>;
}

export const StructureInput = ({structure, setStructure}: StructureInputProps) => {
  const deferredStructure = useDeferredValue(structure);
  const lastFileName = useRef('');

  const jsonValue = useMemo(() => deferredStructure ? JSON.stringify(deferredStructure, null, 2) : '', [deferredStructure]);

  const tryChangeValue = (value?: string | null) => {
    if (!value) {
      setStructure(null);
      return;
    }

    try {
      setStructure(JSON.parse(value));
    } catch {}
  }

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    try {
      tryChangeValue(e.target.value);
    } catch {}
  }

  return (
    <Space direction="vertical" className={styles.fullWidth}>
      <Upload.Dragger
        name="file"
        showUploadList={false}
        beforeUpload={file => {
          lastFileName.current = file.name;

          const reader = new FileReader();

          reader.onload = e => {
            tryChangeValue(e.target?.result && String(e.target.result));
          };
          reader.readAsText(file);

          // Prevent upload
          return false;
        }}
      >
        <p className={`ant-upload-drag-icon ${styles.halfTransparent}`}>
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Upload.Dragger>
      <div className={styles.relative}>
        <Input.TextArea value={jsonValue} onChange={onChange} rows={20} />
        <Button className={styles.downloadButton} onClick={() => downloadJSON(structure, `edited_${lastFileName.current}`)}><DownloadOutlined/> Download</Button>
      </div>
    </Space>
  );
};
